<template>
  <div>
    <div class="flix-form-group" v-if="!demo">
      <select class="flix-form-control" @change.prevent="setData" v-model="method" >
        <option v-for="style in styles" :key="style" :value="style">{{ $tc('message.'+style, 1, {name: ''}) }}</option>
      </select>
    </div>
    <div :key="data.method">
      <footerCustomPage :onEdit="function(id) { onEdit(data.ID) }" :data="data" v-if="data.method === 'targetPage'"/>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    footerCustomPage () { return import('@/components/targetPage/footerCustomPage') }
  },
  props: {
    data: Object,
    onEdit: Function
  },
  mounted () {

  },
  computed: {

  },
  data () {
    return {
      demo: true,
      method: this.data.method,
      styles: ['targetPage', 'systemNews'/*, 'website' */]
    }
  },
  methods: {
    setData () {
      this.data.method = this.method
      this.$store.dispatch('setTargetPage', {
        user: this.$getUserVariables().user.md5_id,
        ID: this.data.ID,
        data: this.data.layout,
        meta: { assistents: this.data.assistents, method: this.data.method },
        callback: function () { }
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
